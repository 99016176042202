import { useState, Suspense, lazy } from "react";
import { useMediaQuery } from "react-responsive";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import VideosSwiper from "../../lists/videos-swiper/videos-swiper.component";
import { Loading } from "../../loading/loading.component";
import { MEDIA_QUERY_LIMITS } from "../../../config";

const Error = lazy(() => import("../../../components/error/error.component"));

/*
 * Its a Query on Category Type with added custom fields from resolvers
 * 1) categoryVideosNumber:
 *  [ ] For what we use it? not clear? TODO should we use in the mechanism for
 *  pagination? It is used on category grid page and in categoryPage component
 * 2) categoryVideos: from custom resolver "resolveCategoryVideos".
 *  It has input for paginating the categoryVideos. It return slices of the
 *  collection categoriesVideos (Which already has the correct order by
 *  categoryScore)
 *
 */

const CATEGORY_VIDEOS = gql`
  query CATEGORY_VIDEOS(
    $query: CategoryQueryInput
    $input: PaginatedVideosInput
  ) {
    category(query: $query) {
      _id
      name
      categoryVideosNumber
      categoryVideos(input: $input) {
        _id
        displayName
        people {
          _id
          name
        }
        episodePoster
        plot
      }
    }
  }
`;

const SwiperCategoryVideos = ({ category, withIcon = false }) => {
  const [completed, setCompleted] = useState(false);
  const isDesktop = useMediaQuery({
    minWidth: MEDIA_QUERY_LIMITS.minWidthDesktop,
  });

  const query = {
    name: category,
  };

  const initialPage = {
    skip: 0,
    first: 5,
  };

  const { loading, error, data, fetchMore } = useQuery(CATEGORY_VIDEOS, {
    variables: {
      query,
      input: initialPage,
    },
  });

  const padding = withIcon
    ? `var(--root-vertical-space-0) 0 var(--root-vertical-space-0) ${
        !isDesktop
          ? "var(--root-padding-swiper-mobile)"
          : "calc(var(--root-inline-header-width) + var(--root-inline-offset-videos-by-category-home))"
      }`
    : `var(--root-vertical-space-0) 0 var(--root-vertical-space-0) ${
        !isDesktop
          ? "var(--root-padding-swiper-mobile)"
          : "var(--root-horizontal-space-1)"
      }`;

  const moveLeftArrow = withIcon
    ? "var(--swiper-move-arrow-left-by-cat-home)"
    : undefined;

  const loadMore = () => {
    // console.log("load more");
    if (completed) {
      // console.log("Pagination is completed do nothing");
      return;
    }

    const skip = data?.category?.categoryVideos?.length || 0;
    const input = {
      ...initialPage,
      skip,
    };

    fetchMore({
      variables: {
        input,
      },
    }).then((fetchMoreResult) => {
      // console.log(fetchMoreResult);
      if (fetchMoreResult.data?.category?.categoryVideos?.length === 0) {
        setCompleted(true);
      }
    });
  };

  // data && console.log(`${category}`, data);

  // error && console.log("error", error);
  // loading && console.log("loading", loading);

  return (
    <div
      style={{
        minHeight: "var(--root-video-card-dimension-height)",
      }}
    >
      {error ? (
        <Suspense
          fallback={<Loading minHeight={"var(--root-min-height-video-row)"} />}
        >
          <Error message={error.message} />
        </Suspense>
      ) : loading ? (
        <>
          <h1>Loading</h1>
          <Loading minHeight={"var(--root-min-height-video-row)"} />
        </>
      ) : (
        data && (
          <VideosSwiper
            data={data?.category?.categoryVideos}
            padding={padding}
            moveLeftArrow={moveLeftArrow}
            loadMore={loadMore}
          />
        )
      )}
    </div>
  );
};

export default SwiperCategoryVideos;
