import styled from "styled-components/macro";
import { MEDIA_QUERY_LIMITS } from "../../config";
import { NavLink } from "../navigation/navigation.styles";

export const FooterContainer = styled.div`
  padding: max(var(--root-vertical-space-3), 2rem)
    var(--root-horizontal-space-3);
  font-size: var(--p-size);
  background: var(--root-background-color-footer);
  margin-top: var(--root-row-margin);

  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;

  a[href^="tel:"],
  a[href^="mailto:"] {
    color: var(--root-color-default);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LogoContainer = styled.div`
  height: calc(var(--p-size) * 2);
  svg {
    width: auto;
    height: 100%;
  }
  display: inline-block;
`;
export const FooterNavLinkContainer = styled.div``;
export const FooterInfoContainer = styled.div`
  flex-basis: 100%;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
    flex-basis: 50%;
  }
  text-align: left;
`;

export const ContactsContainer = styled.div`
  padding: var(--root-vertical-space-1) 0;
`;

export const FooterNavLink = styled(NavLink)`
  color: light-white;
  font-size: var(--p-size);
  font-weight: var(--root-font-weight-bold);
  text-align: left;
`;
