import styled from "styled-components/macro";
import * as Separator from "@radix-ui/react-separator";
import ViewRowHeader from "../view-row-header/view-row-header.component";
import { Wrapper } from "../view-row-header/view-row-header.styles.jsx";
import { H2 } from "../../styles/common";

import IconCategory from "../../icon-category/icon-category.component";

import { getCategoryColor } from "../../../utils/custom/custom.utils";

const WrapperDiv = styled(Wrapper)`
  align-items: center;
  justify-content: flex-start;
`;

const TitleContainer = styled.div`
  ${H2} {
    padding: 0 0.4rem;
    background: white;
    white-space: nowrap;
    line-heigth: 1;
  }
`;

const LineRight = styled(Separator.Root)`
  flex-grow: 1;
  flex-shrink: 0;
  border-bottom: 2px solid var(--color);
  height: 0.8rem;
`;

const ViewIconRowHeader = ({ category, children, ...delegated }) => {
  const color = getCategoryColor(category);

  return (
    <ViewRowHeader
      type={"stack"}
      color={color}
      justifyContentViewRowHeader={"flex-start"}
      {...delegated}
    >
      <WrapperDiv>
        <IconCategory
          category={category}
          width={
            "clamp(36px, 5.128205128vw, 60px)"
          } /* take layout mobile width (1170px). icon width 60px ergo 60: 1170 = x : 100  x = 60 /11.7*/
        />
        <TitleContainer>{children}</TitleContainer>
        <LineRight />
      </WrapperDiv>
    </ViewRowHeader>
  );
};

export default ViewIconRowHeader;
