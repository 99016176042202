import { ReactComponent as Logo } from "../../assets/logo-dark.svg";
import { H5 } from "../../components/styles/common";
import {
  FooterContainer,
  FooterNavLinkContainer,
  ContactsContainer,
  LogoContainer,
  FooterInfoContainer,
  FooterNavLink,
} from "./footer.styles";

const Footer = () => (
  <FooterContainer>
    <FooterInfoContainer>
      <LogoContainer>
        <Logo
          style={{
            "--color": "var(--root-color-default)",
          }}
        />
      </LogoContainer>
      is a television about fashion, beauty, travel, design, automotive, events,
      and in general lifestyle trends, run and presented by influencers.{" "}
      <ContactsContainer>
        <H5
          style={{
            "--font-weight": "var(--root-font-weight-bold)",
            "--font-size": "var(--p-size)",
          }}
        >
          <a
            href="mailto:info@utrendtv.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@utrendtv.com
          </a>
        </H5>
        <p
          style={{
            "--font-weight": "var(--root-font-weight-bold)",
            "--font-size": "var(--p-size)",
          }}
        >
          Utrend srl
        </p>
        <p
          style={{
            "--font-weight": "var(--root-font-weight-bold)",
            "--font-size": "var(--p-size)",
          }}
        >
          Via Decemviri, 8
        </p>
        <p
          style={{
            "--font-weight": "var(--root-font-weight-bold)",
            "--font-size": "var(--p-size)",
          }}
        >
          20137 Milano
        </p>
      </ContactsContainer>
    </FooterInfoContainer>

    <FooterNavLinkContainer>
      <FooterNavLink to="/privacy">Privacy Policy</FooterNavLink>
    </FooterNavLinkContainer>
  </FooterContainer>
);

export default Footer;
