import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import SearchForm from "../forms/search-form/search-form.component";
import { searchValidation as validate } from "../../utils/custom/custom.utils";

const schema = yup.object().shape({
  search: yup.string().min(3).max(24).required("required field"),
});

const SendSearchForm = () => {
  const navigate = useNavigate();
  const [isFormValid, setIsformValid] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (input) => {
    if (validate(input)) {
      navigate(`/search/${input?.toLowerCase()?.trim()}`);
      setSearchTerm("");
    } else {
      toast(
        "Your input is not valid, too short (2) or too long (25). Please verify if you have used any special character and remove it.",
      );
    }
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    handleSearch(searchTerm);
  };

  const onInputChange = async (event) => {
    setSearchTerm(event.target.value);

    setIsformValid(
      await schema.isValid(
        { search: event.target.value },
        {
          abortEarly: false, // Prevent aborting validation after first error
        },
      ),
    );
  };

  return (
    <SearchForm
      isFormValid={isFormValid}
      searchTerm={searchTerm}
      onInputChange={onInputChange}
      onFormSubmit={onFormSubmit}
    />
  );
};

export default SendSearchForm;
