import { ReactComponent as SearchIcon } from "../../../assets/icons/various/search.svg";
import { SPAN } from "../../styles/common";

import {
  FormContainer,
  SubmitButton,
  InputWithLabelContainer,
  WrapperDiv,
  SearchLabel,
  SearchInput,
  SerchContainerIcon,
} from "./search-form.styles";

const SearchForm = ({
  isFormValid,
  searchTerm,
  onInputChange,
  onFormSubmit,
}) => {
  return (
    <FormContainer role="search" onSubmit={onFormSubmit} action="submit">
      <InputWithLabelContainer
        style={{
          "--color": isFormValid
            ? "var(--root-color-default)"
            : "var(--secondaryColor)",
        }}
      >
        <SearchLabel htmlFor="search">
          <SPAN
            style={{
              "--color": "var(--root-color-default)",
              "--font-weight": "var(--root-font-weight-bold)",
              "--font-size": "var(--h6-size)",
              "--line-height": 1,
              "--text-transform": "capitalize",
            }}
          >
            Search:
          </SPAN>
        </SearchLabel>
        <SearchInput
          aria-label="Search through site content"
          autocomplete="off"
          id="search"
          // required={required}
          type="search"
          value={searchTerm}
          onChange={onInputChange}
        />
      </InputWithLabelContainer>
      <WrapperDiv>
        <SubmitButton type="submit" disabled={!searchTerm || !isFormValid}>
          <SerchContainerIcon>
            <SearchIcon />
          </SerchContainerIcon>
        </SubmitButton>
      </WrapperDiv>
    </FormContainer>
  );
};

export default SearchForm;
