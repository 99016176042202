import styled from "styled-components/macro";

import { noWrap, boxShadow } from "../../styles/common";

// TODO calc width based on the height we have to cover
export const CategoryCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const CategoryLabel = styled.div`
  background: ${({ backgroundColor }) =>
    backgroundColor || "var(--root-background-color-default)"};
  transform-origin: left bottom;
  transform-box: fill-box;
  transform: rotate(-65deg) skew(25deg)
    translateY(calc(var(--root-icon-category-vertical-margin) + 0.520833333vw));
  ${boxShadow};
  width: calc(
    var(--root-icon-container-total-width) / 0.906
  ); /* 0.906 = cos(65deg) */
`;

export const CategoryTitle = styled.h2`
  letter-spacing: var(--letterSpacing, 1px);
  font-size: var(--smallerFont, var(--h3-size));
  ${noWrap};
  text-align: center;
  color: white;
  // padding: 0.656410256vw;
  padding-top: var(--root-horizontal-space-1);
  padding-bottom: var(--root-horizontal-space-1);
  text-transform: uppercase;
  transform: skew(-25deg);
`;
