import * as HoverCard from "@radix-ui/react-hover-card";
import styled from "styled-components/macro";

import {
  centeringAbsolutePositionContainer,
  boxShadow,
  PosterContainer,
  zoomIn,
} from "../../components/styles/common";

export const HoverCardContainer = styled(HoverCard.Content)`
  background: #f0ebe5;
  border-radius: var(--root-border-radius-0);
  border: max(0.729166667vw, 14px) solid var(--borderColor, var(--salmonColor));
  border-left: 0;
  border-right: 0;
  width: var(--root-hover-card-container-width);
  ${boxShadow}
  transform-origin: var(--radix-hover-card-content-transform-origin);
  animation: ${zoomIn} 0.3s;
`;

export const HoverCardPosterContainer = styled(PosterContainer)`
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
`;

export const HoverCardPlotAndTitleContainer = styled.div`
  margin: var(--root-horizontal-space-1);
  text-align: left;
`;

export const HoverCardTitle = styled.h2`
  color: var(--root-color-default);
  text-align: left;
  text-transform: uppercase;
  font-size: var(--p-size);
`;

export const TriggerContainer = styled.div`
  position: absolute;
  width: 65%;
  height: 55%;
  ${centeringAbsolutePositionContainer};

  &:hover {
    cursor: pointer;
  }
`;
