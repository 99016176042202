import styled, { css } from "styled-components/macro";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";

import { CaretDownIcon } from "@radix-ui/react-icons";

import {
  boxShadow,
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
  enterFromLeft,
  // enterFromTop,
  // exitToTop,
  enterFromRight,
  exitToRight,
  exitToLeft,
  fadeIn,
  fadeOut,
} from "../../../components/styles/common";
import { MEDIA_QUERY_LIMITS } from "../../../config";

export const DropdownMenuContent = styled(DropdownMenu.Content)`
  width: 100vw;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthLargeMobile}px) {
    width: 80vw;
  }

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    width: 45vw;
  }

  border-radius: var(--root-border-radius-0) var(--root-border-radius-0) 0 0;
  /* border: var(--root-border-size) solid white; */
  background-color: white;
  /* outline: var(--root-border-size) solid white !important; */
  ${boxShadow};
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="top"],
  &[data-side="top"] {
    animation-name: ${slideDownAndFade};
  }
  &[data-side="right"],
  &[data-side="right"] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-side="bottom"],
  &[data-side="bottom"] {
    animation-name: ${slideUpAndFade};
  }
  &[data-side="left"] {
    animation-name: ${slideRightAndFade};
  }
`;

export const NavigationMenuRoot = styled(NavigationMenu.Root)`
  position: relative;
  padding: var(--root-vertical-space-1) 0;
  display: flex;
  z-index: 1;
`;

export const NavigationMenuList = styled(NavigationMenu.List)`
  padding-left: var(--root-horizontal-space-1);
  display: flex;
  align-items: center;
  justify-content: end;
  list-style: none;
  margin: 0;
`;

const CSSNavigationMenuItem = css`
  padding: 0 var(--root-horizontal-space-0);
  outline: none;
  user-select: none;
  font-weight: var(--root-font-weight-bold);
  line-height: 1;
  font-size: var(--h5-size);
  color: var(--root-default-color);
  text-transform: capitalize;
`;

// A top level menu item, contains a link or trigger with content combination.
export const NavigationMenuItem = styled(NavigationMenu.Item)`
  ${CSSNavigationMenuItem};
`;

export const NavigationMenuLink = styled(NavigationMenu.Link)`
  ${CSSNavigationMenuItem};
  display: block;
  text-decoration: none;
`;

export const NavigationMenuTrigger = styled(NavigationMenu.Trigger)`
  ${CSSNavigationMenuItem};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--root-border-size);
`;

export const CaretDown = styled(CaretDownIcon)`
  position: relative;
  top: 1px;
  transition: transform 250ms ease;
  --size: var(--h3-size);
  width: var(--size);
  height: var(--size);

  ${NavigationMenuTrigger}[data-state="open"] & {
    transform: rotate(-180deg);
  }
`;

export const NavigationMenuContent = styled(NavigationMenu.Content)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation-duration: 250ms;
  animation-timing-function: ease;

  &[data-motion="from-start"] {
    animation-name: ${enterFromLeft};
  }
  &[data-motion="from-end"] {
    animation-name: ${enterFromRight};
  }
  &[data-motion="to-start"] {
    animation-name: ${exitToLeft};
  }
  &[data-motion="to-end"] {
    animation-name: ${exitToRight};
  }
`;

export const ViewportPosition = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 100%;
  left: 0;
  perspective: 2000px;
`;

export const NavigationMenuViewport = styled(NavigationMenu.Viewport)`
  position: relative;
  background-color: rgba(255, 255, 255, 0.93);
  transform-origin: top center;
  width: 100%;
  border-radius: 0 0 var(--root-border-radius-0) var(--root-border-radius-0);
  overflow: hidden;
  height: var(--radix-navigation-menu-viewport-height);
  transition:
    width,
    height,
    300ms ease;

  &[data-state="open"] {
    animation: ${fadeIn} 200ms ease;
  }

  &[data-state="closed"] {
    animation: ${fadeOut} 200ms ease;
  }
`;

export const BlackLogo = styled.img`
  width: max(116px, 6vw);
`;
