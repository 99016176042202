import styled from "styled-components/macro";
import CrossIcon from "../../../assets/icons/various/cross.svg";

import { MEDIA_QUERY_LIMITS } from "../../../config";

export const FormContainer = styled.form`
  align-items: center;
  border-radius: 5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  min-width: 30vw;
  overflow: hidden;
  padding-left: var(--root-horizontal-space-2);
  background: rgba(var(--root-background-color-white-1-rgb), 0.6);

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
    background: white;
    border: none;
  }
`;

export const WrapperDiv = styled.div`
  --size: calc(var(--root-vertical-space-0) * 0.32);
  background: var(--primaryColor);
  border-bottom-left-radius: var(--root-border-radius-0);
  width: min-content;
  padding: var(--size) 0;
  margin-left: max(var(--root-horizontal-space-0), 8px);

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    border-bottom-left-radius: var(--root-border-radius-2);
  }
`;

export const SerchContainerIcon = styled.div`
  width: max(15px, 1.40625vw);
  margin: 0 auto;
`;

export const SubmitButton = styled.button`
  display: inline-block;
  width: max(4.166666667vw, 33px);
  color: white;
  height: 100%;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    color: var(--secondaryColor);
  }
`;

export const InputWithLabelContainer = styled.div`
  --size: calc(var(--root-vertical-space-0) * 0.32);
  color: var(--color);
  display: flex;
  align-items: center;
  padding: var(--size) 0;
  flex-grow: 1;
  gap: max(8px, var(--root-horizontal-space-0));

  [type="search"]::-webkit-search-cancel-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;

    height: var(--root-radix-icon-size);
    width: var(--root-radix-icon-size);
    background-image: url(${CrossIcon});
    background-size: cover;
  }
`;

export const SearchLabel = styled.label``;
export const SearchInput = styled.input`
  flex-grow: 1;
  line-height: 1;
  font-size: 16px; /* prevent safari from auto zoom */
  max-width: 30vw;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthLargeMobile}px) {
    max-width: none;
  }

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    font-size: var(--h6-size);
  }
`;
