import { videoAssetsPath } from "../../utils/custom/custom.utils";
import { useNavigate } from "react-router-dom";
import * as HoverCard from "@radix-ui/react-hover-card";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

import Image from "../../components/image/image-component";

import { DEFAULT_MOBILE_HOVER_PADDING } from "../../config";

import {
  HoverCardContainer,
  HoverCardPosterContainer,
  HoverCardTitle,
  TriggerContainer,
  HoverCardPlotAndTitleContainer,
} from "./video-test-card-hover-trigger.styles.jsx";

import PosterFallback from "../../assets/poster-fallback.png";

const VideoCardHoverTrigger = ({
  _id,
  displayName,
  sideOffset,
  collisionPadding = DEFAULT_MOBILE_HOVER_PADDING,
  preview,
  episodePoster,
  borderColor,
  ...rest
}) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(`/videos/${_id}`);
    // navigate(0);
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <HoverCard.Root openDelay={1200} closeDelay={400}>
      <HoverCard.Trigger>
        <TriggerContainer onClick={onClickHandler}>
          <VisuallyHidden.Root>{_id}</VisuallyHidden.Root>
        </TriggerContainer>
        <HoverCard.Portal>
          <HoverCardContainer
            avoidCollisions={true}
            collisionPadding={collisionPadding}
            sideOffset={sideOffset}
          >
            <>
              <HoverCardPosterContainer
                ratio={16 / 9}
                onClick={onClickHandler}
                style={{
                  "--borderColor": { borderColor },
                }}
              >
                <Image
                  src={
                    preview ||
                    videoAssetsPath(episodePoster).posterLarge ||
                    PosterFallback
                  }
                  alt={displayName || "Nome episodio"}
                />
              </HoverCardPosterContainer>
              <HoverCardPlotAndTitleContainer>
                <HoverCardTitle>
                  {displayName || "Nome Episodio"}
                </HoverCardTitle>
                <HoverCardTitle>_id: {_id}</HoverCardTitle>
                <HoverCardTitle>poster: {episodePoster}</HoverCardTitle>
                <HoverCardTitle>videoId: {rest.videoId}</HoverCardTitle>
              </HoverCardPlotAndTitleContainer>
            </>
          </HoverCardContainer>
        </HoverCard.Portal>
      </HoverCard.Trigger>
    </HoverCard.Root>
  );
};

export default VideoCardHoverTrigger;
