import { useWindowWidth } from "@react-hook/window-size";
import { videoAssetsPath } from "../../../utils/custom/custom.utils";
import Image from "../../image/image-component";
import { PosterContainer } from "../../styles/common";
import VideoCardHoverTrigger from "../video-card-hover-trigger/video-card-hover-trigger.component";

import { VideoCardStoryContainer, Rank } from "./video-card-story.styles";

const DEFAULT_STORY = {
  width: "var(--root-video-card-story-width)",
};

const VideoCardStory = ({
  width = DEFAULT_STORY.width,
  _id,
  displayName,
  episodePosterPortrait,
  rank,
  ...rest
}) => {
  const viewportWidth = useWindowWidth();

  // distance in pixels from trigger has to be relative to windowView since video-card changes
  const sideOffset = viewportWidth * -0.26;

  const props = {
    ...rest,
    _id,
    episodePosterPortrait,
    displayName,
    sideOffset,
  };

  return (
    <VideoCardStoryContainer
      style={{
        "--widthCard": width,
      }}
    >
      <PosterContainer ratio={2 / 3}>
        <Image
          src={videoAssetsPath(episodePosterPortrait).posterPortrait}
          alt={displayName || "Nome episodio"}
        />
      </PosterContainer>
      {rank && <Rank>{rank.toString().padStart(2, 0)}</Rank>}
      <VideoCardHoverTrigger {...props} />
    </VideoCardStoryContainer>
  );
};

export default VideoCardStory;
