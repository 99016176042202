import { lazy } from "react";
import { useMediaQuery } from "react-responsive";
import { SwiperSlide } from "swiper/react";
import VideoCard from "../../cards/video-card/video-card.component";
import ViewPaginatedSwiper from "../../views/view-paginated-swiper/view-paginated-swiper.component";
import { DEFAULT_VIDEO_CARD, MEDIA_QUERY_LIMITS } from "../../../config";

const Error = lazy(() => import("../../../components/error/error.component"));

const VideosSwiper = ({
  data = [],
  padding,
  borderColor = DEFAULT_VIDEO_CARD.borderColor,
  ...rest
}) => {
  const isDesktop = useMediaQuery({
    minWidth: MEDIA_QUERY_LIMITS.minWidthDesktop,
  });

  if (!padding) {
    padding = `var(--root-vertical-space-0) 0 var(--root-vertical-space-0) ${
      !isDesktop
        ? "var(--root-padding-swiper-mobile)"
        : "var(--root-horizontal-space-1)"
    }`;
  }

  return (
    <ViewPaginatedSwiper arrowsColor={"white"} padding={padding} {...rest}>
      {data.length > 0 ? (
        data.map((item, i) => (
          <SwiperSlide
            key={item._id ? item._id : i.toString()}
            style={{ width: "min-content" }}
          >
            <VideoCard {...item} borderColor={borderColor} />
          </SwiperSlide>
        ))
      ) : (
        <Error />
      )}
    </ViewPaginatedSwiper>
  );
};

export default VideosSwiper;
