import { ViewRowContainer } from "./view-row-content.styles";

const ViewRowContent = (props) => {
  return (
    <ViewRowContainer
      style={{
        "--overflow": props.overflow,
        "--paddingRowContent": props.padding,
      }}
    >
      {props.content}
    </ViewRowContainer>
  );
};

export default ViewRowContent;
