import styled from "styled-components/macro";

import { MEDIA_QUERY_LIMITS } from "../../../config";

export const Arrow = styled.button`
  color: var(--color, var(--secondaryColor));
  display: none;
  font-weight: var(--root-font-weight-bold);
  height: calc(var(--swiper-navigation-size) * 2.5);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(var(--swiper-navigation-size) * 2.5);
  z-index: 10;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    display: block;
  }

  &.swiper-videos-list-button-prev,
  &.swiper-rtl &.swiper-videos-list-button-next {
    left: var(--moveLeftArrow, 1vw);
  }

  &.swiper-videos-list-button-next,
  &.swiper-rtl &.swiper-videos-list-button-prev {
    right: 1vw;
  }

  &.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: auto;
  }
`;
