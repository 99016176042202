import { forwardRef } from "react";
import { useMediaQuery } from "react-responsive";

import { List, Slot } from "../menu-list-item/menu-list-item.styles";

import { NavigationMenuLink } from "../menu-content/menu-content.styles";
// import { getCitySvgText } from "../../../config.js";
import { cityAssetsPath } from "../../../utils/custom/custom.utils";
import { MEDIA_QUERY_LIMITS } from "../../../config";

import {
  AvatarRoot,
  AvatarImage,
  AvatarFallback,
} from "./menu-content-cities.styles";
import { H5 } from "../../styles/common";

const MenuCityListItem = forwardRef(
  ({ children, avatar, name, ...props }, forwardedRef) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a {...props} ref={forwardedRef}>
            <Slot>
              <AvatarRoot>
                <AvatarImage
                  src={cityAssetsPath(avatar).avatar}
                  alt={`city ${name}`}
                />
                <AvatarFallback delayMs={600}>
                  {name.slice(0, 2)}
                </AvatarFallback>
              </AvatarRoot>
              {children}
            </Slot>
          </a>
        </NavigationMenuLink>
      </li>
    );
  },
);

const MenuContentCities = ({ data = [] }) => {
  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  return (
    data.length > 0 && (
      <List>
        {data.map((item, i) => (
          <MenuCityListItem
            key={item._id ? item._id : i.toString()}
            href={`/cities/${item.name}`}
            avatar={item.avatar}
            name={item.name}
          >
            <H5
              style={{
                "--text-transform": "uppercase",
                "--font-weight": "var(--root-font-weight-bold)",
                whiteSpace: "nowrap",
                "--font-size": isMobile
                  ? "calc(var(--root-font-size-header-mobile) * 0.7)"
                  : "var(--h5-size)",
              }}
            >
              {item.name}
            </H5>
          </MenuCityListItem>
        ))}
      </List>
    )
  );
};

export default MenuContentCities;
