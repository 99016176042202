import { useMediaQuery } from "react-responsive";
import { forwardRef } from "react";
import IconCategory from "../../../components/icon-category/icon-category.component";
import { List, Slot } from "../menu-list-item/menu-list-item.styles";
import { NavigationMenuLink } from "../menu-content/menu-content.styles";
import { H5 } from "../../styles/common";
import { MEDIA_QUERY_LIMITS } from "../../../config";
import { getCategoryUiName } from "../../../utils/custom/custom.utils";

const MenuCategoriesListItem = forwardRef(
  ({ children, name, ...props }, forwardedRef) => (
    <li>
      <NavigationMenuLink asChild>
        <a {...props} ref={forwardedRef}>
          <Slot>
            <IconCategory
              isLink={false}
              category={name}
              width="max(30px, 2.34375vw)"
              height="max(30px, 2.34375vw)"
            />
            {children}
          </Slot>{" "}
        </a>
      </NavigationMenuLink>
    </li>
  ),
);

const MenuContentCategories = ({ data = [] }) => {
  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  return (
    data.length > 0 && (
      <List>
        {data.map((item, i) => (
          <MenuCategoriesListItem
            key={item._id ? item._id : i.toString()}
            href={`/categories/${item.name}`}
            name={item.name}
          >
            <H5
              style={{
                "--text-transform": "uppercase",
                "--font-weight": "var(--root-font-weight-bold)",
                whiteSpace: "nowrap",
                "--font-size": isMobile
                  ? "calc(var(--root-font-size-header-mobile) * 0.7)"
                  : "var(--h5-size)",
              }}
            >
              {getCategoryUiName(item.name)}
            </H5>
          </MenuCategoriesListItem>
        ))}
      </List>
    )
  );
};

export default MenuContentCategories;
