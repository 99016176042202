import {
  LeadHomeTitleContainerStyle,
  LeadHomeTitleStyle,
} from "./lead-home-title.styles";

const LeadHomeTitle = ({ title = "uTrendTv video" }) => {
  return (
    <LeadHomeTitleContainerStyle>
      <LeadHomeTitleStyle
        style={{
          "--line-height": "1",
          "--color": "var(--root-color-default)",
          "--text-transform": "uppercase",
          "--font-weight": "var(--root-font-weight-bold)",
          wordBreak: "keep-all",
        }}
      >
        {title}
      </LeadHomeTitleStyle>
    </LeadHomeTitleContainerStyle>
  );
};

export default LeadHomeTitle;
