import styled from "styled-components/macro";

import { CategoryCardContainer } from "../../components/cards/category-card/category-card.styles";

export const IconCategoryContainer = styled.div`
  cursor: pointer;
  width: var(--width, 100%);

  justify-self: center;

  ${CategoryCardContainer} & {
    margin: var(--root-icon-category-vertical-margin) 0;
  }
`;
