// import { useScript } from "usehooks-ts";
import { useState, useEffect, createContext, useContext } from "react";

const OtteraContext = createContext();

export const useOttera = () => {
  const ottera = useContext(OtteraContext);
  if (!ottera) {
    throw new Error(`You must call useOttera() inside of a <OtteraProvider />`);
  }
  return ottera;
};

export const OtteraProvider = ({ children }) => {
  // const status = useScript(
  //   `https://api-ott.utrendtv.com/javascriptLib?version=13`,
  //   {
  //     removeOnUnmount: false,
  //   }
  // );

  const [ottera, setOttera] = useState(null);
  const [ping, setPing] = useState(false);

  // const handleMessage = (event) => {
  //   console.log("[Events]: start listening when ottera lib is loaded");
  //   console.log("event origin", event.origin);
  //   console.log("window.location", window.location.origin);
  //   console.log("event data", event.data);
  //
  //   // if (event.origin !== window.location.origin || !event.data.name) {
  //   //   console.log("something was wrong");
  //   //   return;
  //   // }
  //
  //   if (event.data.name === "ottera_jslib_loaded") {
  //     console.log("[SET] ottera loaded");
  //     setOttera(window.OTTera);
  //   }
  // };

  useEffect(() => {
    if (typeof window.OTTera !== "undefined") {
      console.log("[SET] OTTera language");
      window.OTTera.getLanguage();
      console.log("[SET] OTTera provider");
      setOttera(window.OTTera);
    } else {
      setTimeout(() => {
        console.log("[WARN] Ottera not loaded yet");
        setPing(!ping);
      }, 800);
    }
  }, [ping]);

  const wrapped = { ...ottera };

  return (
    <OtteraContext.Provider value={wrapped}>{children}</OtteraContext.Provider>
  );
};
