import { useNavigate } from "react-router-dom";
import { CATEGORIES_CLASSES } from "../../config";
import { IconCategoryContainer } from "./icon-category.styles";
import { ReactComponent as Motori } from "../../assets/icons/categories/motori.svg";
import { ReactComponent as Bellezza } from "../../assets/icons/categories/bellezza.svg";
import { ReactComponent as Eventi } from "../../assets/icons/categories/eventi.svg";
import { ReactComponent as Fintech } from "../../assets/icons/categories/fintech.svg";
import { ReactComponent as Design } from "../../assets/icons/categories/design.svg";
import { ReactComponent as Ambiente } from "../../assets/icons/categories/ambiente.svg";
import { ReactComponent as Fashion } from "../../assets/icons/categories/fashion.svg";
import { ReactComponent as Cucina } from "../../assets/icons/categories/cucina.svg";
import { ReactComponent as Musica } from "../../assets/icons/categories/musica.svg";
import { ReactComponent as Viaggi } from "../../assets/icons/categories/viaggi.svg";
import { ReactComponent as Sport } from "../../assets/icons/categories/sport.svg";
import { ReactComponent as Tecnologia } from "../../assets/icons/categories/tecnologia.svg";

import { ReactComponent as Arte } from "../../assets/icons/categories/arte.svg";
import { ReactComponent as Architettura } from "../../assets/icons/categories/architettura.svg";
import { ReactComponent as Spettacolo } from "../../assets/icons/categories/spettacolo.svg";
import {
  getCategoryIcon,
  removeSpacesWithOptionalChar,
} from "../../utils/custom/custom.utils";
import CategoryHiddenData from "../category-hidden-data/category-hidden-data.component";

//  [Note]: Keys are in the  DB
// Here we map db category to Icon object
export const getIcon = (category) =>
  ({
    [CATEGORIES_CLASSES.automotive]: Motori,
    [CATEGORIES_CLASSES.beauty]: Bellezza,
    [CATEGORIES_CLASSES.events]: Eventi,
    [CATEGORIES_CLASSES.fintech]: Fintech,
    [CATEGORIES_CLASSES["blue-horizons"]]: Ambiente,
    [CATEGORIES_CLASSES.design]: Design,
    [CATEGORIES_CLASSES.travel]: Viaggi,
    [CATEGORIES_CLASSES.fashion]: Fashion,
    [CATEGORIES_CLASSES.sport]: Sport,
    [CATEGORIES_CLASSES.food]: Cucina,
    [CATEGORIES_CLASSES.music]: Musica,
    [CATEGORIES_CLASSES.technology]: Tecnologia,
    [CATEGORIES_CLASSES.art]: Arte,
    [CATEGORIES_CLASSES.architecture]: Architettura,
    [CATEGORIES_CLASSES.entertainment]: Spettacolo,
  })[category];

//  [Note]: Keys are in the  DB
// Here we map db category to ui color
export const getColor = (category) =>
  ({
    [CATEGORIES_CLASSES.automotive]: "#e02723",
    [CATEGORIES_CLASSES.beauty]: "#f07cb6",
    [CATEGORIES_CLASSES.events]: "#a37fb8",
    [CATEGORIES_CLASSES.fintech]: "#5d35a6",
    [CATEGORIES_CLASSES["blue-horizons"]]: "#289dbe",
    [CATEGORIES_CLASSES.design]: "#ff8011",
    [CATEGORIES_CLASSES.travel]: "#a2d5f2",
    [CATEGORIES_CLASSES.fashion]: "#e63cb6",
    [CATEGORIES_CLASSES.sport]: "#33619c",
    [CATEGORIES_CLASSES.food]: "#ed8922",
    [CATEGORIES_CLASSES.music]: "#871a48",
    [CATEGORIES_CLASSES.technology]: "#3f949c",
    [CATEGORIES_CLASSES.art]: "#ffc900",
    [CATEGORIES_CLASSES.architecture]: "#a3a39c",
    [CATEGORIES_CLASSES.entertainment]: "#a41916",
  })[category];

const IconCategory = ({ category, width, height, isLink = true }) => {
  const categorySlug = removeSpacesWithOptionalChar(category);
  const path = `/categories/${categorySlug}`;
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (!isLink) return;
    navigate(path);
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  };

  const IconCustom = getCategoryIcon(categorySlug);

  return (
    <IconCategoryContainer
      onClick={onClickHandler}
      style={{
        "--height": height,
        "--width": width,
      }}
    >
      {IconCustom && <IconCustom />}

      {isLink && (
        <CategoryHiddenData path={path} data={{ categoryName: category }} />
      )}
    </IconCategoryContainer>
  );
};

export default IconCategory;
