import styled from "styled-components/macro";
import { MEDIA_QUERY_LIMITS } from "../../../config";
import { boxShadowSharp, centeringContainer } from "../../styles/common";

export const FallbackContainer = styled.div`
  ${centeringContainer}
  &:hover {
    cursor: pointer;
  }
  width: 100%;
  height: 100%;
  border-radius: 50%;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
    ${boxShadowSharp};
  }
`;
