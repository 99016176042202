import { useEffect } from "react";
import { useRealmApp } from "../../contexts/realmapp.context.jsx";
import { anonymousLogin } from "../../utils/realm/realm.utils";

import ErrorPage from "../../routes/error-page/error-page.component.jsx";

const AnomWrapper = ({ children }) => {
  const app = useRealmApp();

  // here we want all children has render within a context of current.
  // cound be anim or another user.
  useEffect(() => {
    // If no logged in user, log in
    if (app && !app.currentUser) {
      anonymousLogin(app);
    }
  }, [app, app?.currentUser]);

  if (app?.currentUser) {
    return children;
  } else {
    // Notes: should we use throw? It make the app exit. Less responsive in resurrecting
    return <ErrorPage message="no AnomUser" />;
  }
};

export default AnomWrapper;
