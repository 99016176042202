import Loader from "react-spinners/BeatLoader";
import { LoadingContainer, ImageLoadingContainer } from "./loading.styles";

export const Loading = ({
  minHeight = "20vh",
  color = "var(--secondaryColor)",
}) => {
  return (
    <LoadingContainer
      style={{
        "--min-height": minHeight,
      }}
    >
      <Loader size="0.729166667vw" color={color} margin="0.208333333vw" />
    </LoadingContainer>
  );
};

export const ImageLoading = () => {
  return (
    <ImageLoadingContainer data-testid="loading" className="image-preloader">
      <Loader
        size="0.729166667vw"
        color="var(--secondaryColor)"
        margin="0.208333333vw"
      />
    </ImageLoadingContainer>
  );
};
