import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MEDIA_QUERY_LIMITS } from "../../config";
import { pseudoElement } from "../../components/styles/common";
// import { HamburgerMenuIcon } from "@radix-ui/react-icons";

export const NavLink = styled(Link)`
  color: var(--root-color-default);
  text-decoration: none;
  cursor: pointer;
  font-size: var(--size, var(--p-size));
  font-weight: var(--root-main-font-weight-bold);
  text-transform: uppercase;
`;

export const NavigationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--root-navigation-height);
  padding: 0 var(--root-horizontal-space-1);
  position: relative;

  background: var(--root-background-color-header-mobile);

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
    background: var(--root-background-color-header-desktop);
  }
`;

export const LogoContainer = styled(Link)`
  height: var(--root-navigation-height);
  padding: 1.302083333vw;

  svg {
    width: auto;
    height: 100%;
  }
`;

export const CrossSitesNavigatorContainer = styled.div`
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

export const LogoAndBackButtonCointainer = styled.div`
  display: flex;
  gap: var(--root-horizontal-space-0);
`;

export const SearchBoxcontainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    position: absolute;
    bottom: 1.1vw;
    left: 0;
    width: 100%;
  }
`;

const CrossSiteLevel = styled.div`
  isolation: isolate;
  z-index: 10;
  position: relative;
  align-items: center;
  width: 17.109375vw; /* 328.5px */
  height: 2.0953125vw; /* 40.23px */
  display: flex;
  justify-content: center;

  &:before {
    ${pseudoElement}
    z-index: 20;
    width: 60%;
    right: 0;
    left: auto;
    background: #443b3c;
    transform: skewX(-29.9deg);
    border-radius: 0 0 var(--root-border-radius-1) 0;
  }
  &:after {
    ${pseudoElement}
    z-index: 11;
    width: 60%;
    left: 0;
    right: auto;
    background: #443b3c;
    transform: skewX(29.9deg);
    border-radius: 0 0 0 var(--root-border-radius-1);
  }
`;

export const CrossSitesMenu = styled(CrossSiteLevel)`
  filter: drop-shadow(0px 3px 3px rgb(0 0 0 / 0.6));

  &:before {
    box-shadow: 8px 0 8px -4px rgb(0 0 0 / 1);
    border-bottom: 0.15625vw solid var(--secondaryColor);
    border-right: 0.15625vw solid var(--secondaryColor);
  }

  &:after {
    box-shadow: -8px 0 8px -4px rgb(0 0 0 / 1);
    border-bottom: 0.15625vw solid var(--secondaryColor);
    border-left: 0.15625vw solid var(--secondaryColor);
  }

  h1 {
    z-index: 100;
    line-height: 1;
  }
`;

export const CrossSitesMenuBase = styled(CrossSiteLevel)`
  position: absolute;
  top: 0;
  z-index: 0;
  width: 21.2vw;
  height: 2vw;
`;

export const DropdownMenuContainer = styled(DropdownMenu.Root)`
  button {
    all: unset;
  }
`;
