import styled from "styled-components/macro";
import { boxShadow, VideoCard } from "../../styles/common";

export const VideoCardStoryContainer = styled(VideoCard)`
  --border-radius: var(--root-border-radius-1);
  width: var(--widthCard, var(--root-video-card-story-width));
  ${boxShadow};

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`;

export const Rank = styled.h3`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.260416667vw 0.260416667vw 1.1vw 0.260416667vw;
  color: white;
  background: var(--salmonColor);
  margin-right: min(2.03125vw, 39px);
  transform: skew(-12deg);
  width: min-content;
`;
