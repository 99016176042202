import { useState } from "react";
import { getAcronym } from "../../../utils/custom/custom.utils";
import { H5 } from "../../styles/common";

import { FallbackContainer } from "./avatar-swiper-lazy-context.styles";

const AvatarSwiperLazyContext = (props) => {
  const [avatarMissing, setAvatarMissing] = useState(false);

  const onErrorHandler = () => {
    setAvatarMissing(true);
  };

  return avatarMissing ? (
    <FallbackContainer>
      <H5
        style={{
          "--font-size": "var(--h2-size)",
          "--font-weight": "var(--root-font-weight-bold)",
          "--text-transform": "uppercase",
        }}
      >
        {props.acronym || getAcronym(props.name)}
      </H5>
    </FallbackContainer>
  ) : (
    <img onError={onErrorHandler} alt={props.alt} {...props} />
  );
};

export default AvatarSwiperLazyContext;
