import { lazy, Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import SwiperLeadVideos from "../../../components/swipers/swiper-lead-videos/swiper-lead-videos.component";
import { TabletAndDesktop } from "../../styles/common";
import { Loading } from "../../../components/loading/loading.component.jsx";
import { LeadHomeContainer } from "./lead-home.styles";
import { MEDIA_QUERY_LIMITS } from "../../../config";

const SwiperPeopleVertical = lazy(
  () =>
    import(
      "../../../components/swipers/swiper-people-vertical/swiper-people-vertical.component"
    ),
);

const LeadHome = () => {
  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  return (
    <LeadHomeContainer
      style={{
        "--backgroundColor": `${
          isMobile ? "var(--root-background-color-header)" : "white"
        }`,
      }}
    >
      <SwiperLeadVideos />
      <TabletAndDesktop>
        <Suspense
          fallback={
            <Loading minHeight={"var(--root-video-lead-dimension-height)"} />
          }
        >
          <SwiperPeopleVertical slidesPerView={5} />
        </Suspense>
      </TabletAndDesktop>
    </LeadHomeContainer>
  );
};

export default LeadHome;
