import styled from "styled-components/macro";
import { Swiper } from "swiper/react";
import { MEDIA_QUERY_LIMITS } from "../../../config";

// Import Swiper styles
import "swiper/css";

export const ViewSwiperContainer = styled(Swiper)`
  padding: var(--paddingSwiper, 0);

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next,
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    padding: var(--swiper-vertical-padding-arrow)
      var(--swiper-horizontal-padding-arrow);
    border-radius: var(--root-border-radius-0);
    font-weight: 700;

    @media screen and (max-width: ${MEDIA_QUERY_LIMITS.maxWidthTablet}px) {
      display: none;
    }
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--moveLeftArrow, 1vw);
    padding-right: calc(var(--swiper-horizontal-padding-arrow) * 4);
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 1vw;
    padding-left: calc(var(--swiper-horizontal-padding-arrow) * 4);
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    pointer-events: auto;
    opacity: 0;
  }
`;
