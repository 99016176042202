import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled, { css, keyframes } from "styled-components";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as Popover from "@radix-ui/react-popover";
import { PlusIcon, Cross2Icon, ReloadIcon } from "@radix-ui/react-icons";

import { MEDIA_QUERY_LIMITS } from "../../config";

export const blockQuoteCss = css`
  text-indent: -0.45em;
  @supports (hanging-punctuation: first) {
    & {
      text-indent: 0;
      hanging-punctuation: first;
    }
  }
`;

export const BlockQuote = styled.blockquote`
  ${blockQuoteCss};
`;

export const preventAutoZoomSafafiMobile = css`
  font-size: 16px;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    font-size: var(--h6-size);
  }
`;

export const safariMobileInputFix = css`
  -webkit-user-select: text; /* needed by safari mobile v13 */
`;

export const styledScrollBar = css`
  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`;

// unbreakeble-strings !== from white-space: nowrap
export const noWrap = css`
  white-space: nowrap;
`;

export const fullWidth = css`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const centeringContainer = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const centeringAbsolutePositionContainer = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const pseudoElement = css`
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const boxShadowSharp = css`
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.6);
`;

export const boxShadowStrong = css`
  box-shadow:
    1.9px 1.9px 0.9px rgba(0, 0, 0, 0.06),
    4.2px 4.2px 2.9px rgba(0, 0, 0, 0.078),
    7.3px 7.3px 6.5px rgba(0, 0, 0, 0.088),
    12.1px 12.1px 13.4px rgba(0, 0, 0, 0.095),
    20.9px 20.9px 27.8px rgba(0, 0, 0, 0.099),
    46px 46px 71px rgba(0, 0, 0, 0.1);
`;

export const boxShadow = css`
  box-shadow:
    0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075),
    0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075),
    0 16px 16px hsl(0deg 0% 0% / 0.075);
`;

export const boxShadowInset = css`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px inset;
`;

const pStyle = css`
  color: var(--color, inherit);
  text-transform: var(--text-transform, lowercase);
  font-weight: var(--font-weight, var(--root-font-weight-regular));
  font-style: var(--font-style, normal);
  text-align: var(--text-align, inherit);
  line-height: var(--line-height, var(--root-line-height));
`;

export const LinkStyled = styled(Link)`
  ${pStyle};
  font-size: var(--font-size, inherit);

  &:focus {
    border-radius: var(--root-border-radius-00);
    box-shadow: 0 0 0 var(--2MPx) rgba(var(--veryperiColorRgb), 0.9);
  }
`;

export const H1 = styled.h1`
  ${pStyle};
  font-size: var(--font-size, var(--h1-size));
`;

export const H2 = styled.h2`
  ${pStyle};
  font-size: var(--font-size, var(--h2-size));
`;

export const H3 = styled.h3`
  ${pStyle};
  font-size: var(--font-size, var(--h3-size));
`;

export const H4 = styled.h4`
  ${pStyle};
  font-size: var(--font-size, var(--h4-size));
`;

export const H5 = styled.h5`
  ${pStyle};
  font-size: var(--font-size, var(--h5-size));
`;

export const P = styled.p`
  ${pStyle};
  font-size: var(--font-size, var(--p-size));
`;

export const SPAN = styled.span`
  ${pStyle};
  font-size: var(--font-size, inherit);
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const GlobeIconContainer = styled.div`
  height: var(--height, 100%);

  img {
    height: 100%;
    width: auto;
  }
`;

export const IconContainer = styled.div`
  ${centeringContainer};
  width: var(--width, var(--root-icons-stats-width));
  height: var(--height, var(--root-icons-stats-height));
  svg,
  img {
    width: 100%;
    height: 100%;
  }
  z-index: 10;
`;

export const responsive = css`
  --size: var(--15MPx);
  width: var(--size);
  height: var(--size);
`;

export const MoveContainer = styled.div`
  position: relative;
  top: var(--top, unset);
  right: var(--right, unset);
  bottom: var(--bottom, unset);
  left: var(--left, unset);
  width: var(--width, auto);
  background: var(--background, transparent);
`;

export const VideoCard = styled.div`
  position: relative;
  border-radius: var(--border-radius);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: white;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`;

export const PosterContainer = styled(AspectRatio.Root)`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const fullDivBackground = css`
  background: var(--background-image) no-repeat center center;
  background-size: cover;
`;

export const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const enterFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(var(--root-entrance-exit-to-from));
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const enterFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const enterFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(calc(var(--root-entrance-exit-to-from) * -1));
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const exitToRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(var(--root-entrance-exit-to-from));
  }
`;

export const exitToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(calc(var(--root-entrance-exit-to-from) * -1));
  }
`;

export const exitToTop = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

// export const bounceIn = keyframes`
//   from,
//   20%,
//   40%,
//   60%,
//   80%,
//   to {
//     animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
//   }
//
//   0% {
//     opacity: 0;
//     transform: scale3d(0.3, 0.3, 0.3);
//   }
//
//   20% {
//     transform: scale3d(1.1, 1.1, 1.1);
//   }
//
//   40% {
//     transform: scale3d(0.9, 0.9, 0.9);
//   }
//
//   60% {
//     opacity: 1;
//     transform: scale3d(1.03, 1.03, 1.03);
//   }
//
//   80% {
//     transform: scale3d(0.97, 0.97, 0.97);
//   }
//
//   to {
//     opacity: 1;
//     transform: scale3d(1, 1, 1);
//   }
// `;

export const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
`;

export const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: rotateX(-30deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
`;

export const scaleOut = keyframes`
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 40vw;
  height: 40vw;
`;

export const AngleTopLeftWrapper = styled.div`
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
`;

export const AngleTopLeft = styled(Arrow)`
  background: var(--color, var(--secondaryColor));
  clip-path: polygon(100% 0, 0 0, 0 25%);
`;

export const AngleTopLeftSmall = styled(AngleTopLeft)`
  width: calc(40vw / 100 * 97);
  height: calc(40vw / 100 * 97);
`;

export const AngleBottomRightWrapper = styled.div`
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  filter: drop-shadow(-1px -6px 3px rgba(50, 50, 0, 0.5));
`;

export const AngleBottomRight = styled(Arrow)`
  background: var(--color, var(--secondaryColor));
  clip-path: polygon(100% 100%, 100% 75%, 0 100%);
`;

export const AngleBottomRightSmall = styled(AngleBottomRight)`
  width: calc(40vw / 100 * 97);
  height: calc(40vw / 100 * 97);
`;

export const SimpleContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 15vh;
  ${centeringContainer};
  flex-direction: column;
`;

export const SimpleContainerPage = styled.div`
  width: 100%;
  min-height: calc(100vh - var(--root-navigation-height));
  ${centeringContainer};
  flex-direction: column;
`;

export const ErrorContainer = styled(SimpleContainerPage)`
  max-width: 800px;
  margin: 0 auto;
`;

export const ButtonShow = styled.button`
  --size: max(35px, calc(var(--1Px) * 35));
  font-family: inherit;
  border-radius: 100%;
  height: var(--size);
  width: var(--size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--root-color-default);
  background-color: white;

  &:hover {
    background-color: var(--root-background-color-semi-white);
  }
`;

export const OpenIcon = styled(PlusIcon)`
  ${responsive};
`;
export const CloseIcon = styled(Cross2Icon)`
  ${responsive};
`;
export const Reload = styled(ReloadIcon)`
  ${responsive};
`;

export const PopoverContent = styled(Popover.Content)`
  border-radius: max(var(--root-border-radius-0), 9.6px);
  padding: max(1vw, 19.2px);
  width: max(13.541666667vw, 260px);
  background-color: white;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state="open"][data-side="top"] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state="open"][data-side="right"] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state="open"][data-side="bottom"] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state="open"][data-side="left"] {
    animation-name: ${slideRightAndFade};
  }
`;

export const PopoverArrow = styled(Popover.Arrow)`
  fill: white;
`;

export const PopoverClose = styled(Popover.Close)`
  --size: max(1.302083333vw, 25px);
  --distance: max(0.260416667vw, 5px);
  font-family: inherit;
  border-radius: 100%;
  height: var(--size);
  width: var(--size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--root-color-default);
  position: absolute;
  top: var(--distance);
  right: var(--distance);

  &:hover {
    background-color: var(--violet4);
  }
  &:focus {
    box-shadow: 0 0 0 max(0.104166667vw, 2px) var(--violet7);
  }
`;

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  return isMobile ? children : null;
};

export const SmallMobile = ({ children }) => {
  const isSmallMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthSmallMobile,
  });

  return isSmallMobile ? children : null;
};

export const MobileAndTablet = ({ children }) => {
  const isDesktop = useMediaQuery({
    minWidth: MEDIA_QUERY_LIMITS.minWidthDesktop,
  });

  return !isDesktop ? children : null;
};

export const LargeMobileTabletAndDesktop = ({ children }) => {
  const isSmallMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthSmallMobile,
  });

  return !isSmallMobile ? children : null;
};

export const TabletAndDesktop = ({ children }) => {
  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  return !isMobile ? children : null;
};

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({
    minWidth: MEDIA_QUERY_LIMITS.minWidthDesktop,
  });

  return isDesktop ? children : null;
};
