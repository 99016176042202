import styled from "styled-components/macro";
import * as Separator from "@radix-ui/react-separator";

export const HeaderElementsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const HorizontalSeparator = styled(Separator.Root)`
  flex-grow: 1;
  height: max(2px, 0.104166667vw);
  background: var(--salmonColor);
`;
