import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Suspense, lazy } from "react";
import { Navigation, Grid, A11y } from "swiper";

import { Loading } from "../../loading/loading.component";

import { NUMBER_OF_CITIES } from "../../../config";

import "swiper/css";
import "swiper/css/grid";

import {
  CitiesContainer,
  SwiperCitiesContainer,
  SwiperSlideContainer,
  Arrow,
} from "./swiper-cities.styles";
import CityCard from "../../cards/city-card/city-card.component";

const Error = lazy(() => import("../../../components/error/error.component"));

const CITIES = gql`
  query topCities($input: Int) {
    topCities(input: $input) {
      _id
      name
      icon
      avatar
    }
  }
`;

const SwiperCities = () => {
  const { loading, error, data } = useQuery(CITIES, {
    variables: {
      input: NUMBER_OF_CITIES,
    },
  });

  // data && console.log(data);

  return error ? (
    <Suspense
      fallback={<Loading minHeight={"var(--root-min-height-video-row)"} />}
    >
      <Error message={error.message} />
    </Suspense>
  ) : loading ? (
    <Loading minHeight={"var(--root-min-height-video-row)"} />
  ) : (
    <CitiesContainer>
      <SwiperCitiesContainer
        slidesPerView={2}
        grid={{
          rows: 2,
        }}
        breakpoints={{
          500: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={0}
        grabCursor={true}
        modules={[Navigation, Grid, A11y]}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {data.topCities.length > 0 ? (
          data.topCities.map((item, i) => (
            <SwiperSlideContainer key={item._id ? item._id : i.toString()}>
              <CityCard item={item} />
            </SwiperSlideContainer>
          ))
        ) : (
          <Error />
        )}
      </SwiperCitiesContainer>
      <Arrow className="swiper-button-prev" />
      <Arrow className="swiper-button-next" />
    </CitiesContainer>
  );
};

export default SwiperCities;
