import { useWindowWidth } from "@react-hook/window-size";
import Image from "../../components/image/image-component";
import PosterFallback from "../../assets/poster-fallback.png";
import { PosterContainer } from "../../components/styles/common";

import { videoAssetsPath } from "../../utils/custom/custom.utils";

import { VideoCardContainer } from "./video-test-card.styles";

import VideoTestCardHoverTrigger from "../video-test-card-hover-trigger/video-test-card-hover-trigger.component";

const VideoTestCard = ({
  width,
  _id,
  displayName,
  episodePoster,
  posterType,
  ...rest
}) => {
  // console.log("_id", _id);
  // console.log("episodePoster", episodePoster);
  // console.log("displayName", rest.displayName);
  // console.log("videoId", rest.videoId);
  const viewportWidth = useWindowWidth();

  // distance in pixels from trigger has to be relative to windowView since video-card changes
  const sideOffset = viewportWidth * -0.2;

  const props = {
    ...rest,
    _id,
    episodePoster,
    displayName,
    sideOffset,
  };

  return (
    <VideoCardContainer
      style={{
        "--videoCardWidth": width,
      }}
    >
      <PosterContainer ratio={16 / 9}>
        <Image
          src={
            videoAssetsPath(episodePoster)[
              posterType === "large" ? "posterLarge" : "poster"
            ] || PosterFallback
          }
          alt={displayName || "Nome episodio"}
        />
      </PosterContainer>
      <VideoTestCardHoverTrigger {...props} />
    </VideoCardContainer>
  );
};

export default VideoTestCard;
