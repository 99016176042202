import styled from "styled-components/macro";
import { noWrap } from "../../styles/common";

export const ViewRowHeaderContainer = styled.div`
  color: var(--color, var(--root-color-default));
  margin: var(--marginViewRowHeader, 0);
  padding: var(--paddingViewRowHeader, 0);
  text-align: var(--textAlignViewRowHeader, left);
  text-transform: var(--text-transform, capitalize);
  width: var(--widthViewRowHeader, 100%);
  background: var(--backgroundRowHeader, transparent);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${noWrap};
  }
`;

export const ViewInlineRowHeaderContainer = styled(ViewRowHeaderContainer)`
  align-items: center;
  background: var(--root-background-color-semi-white);
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  left: 0;
  position: absolute;
  text-align: initial;
  top: 0;
  width: var(--widthViewRowHeader, var(--root-inline-header-width));
  z-index: 2;

  h2 {
    position: absolute;
    top: min(-45%, -25px);
  }
`;

export const ViewRowGreenContainer = styled(ViewRowHeaderContainer)`
  position: relative;
  display: flex;
  justify-content: var(--justifyContentViewRowHeader, center);

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: max(4px, 0.208333333vw) solid var(--color);
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: var(--width, 100%);
  display: flex;
  justify-content: center;
`;
