import { useNavigate } from "react-router-dom";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { Logo } from "../../components/logo/logo.component";

import BackButton from "../../components/buttons/back-button/back-button.component";
import MenuButton from "../../components/buttons/menu-button/menu-button.component";
import MenuContent from "../../components/menu/menu-content/menu-content.component";
import SendSearchForm from "../../components/send-search-form/send-search-form.component.jsx";

import {
  NavigationContainer,
  LogoContainer,
  CrossSitesNavigatorContainer,
  SearchBoxcontainer,
  CrossSitesMenu,
  CrossSitesMenuBase,
  DropdownMenuContainer,
  LogoAndBackButtonCointainer,
} from "./navigation.styles";

import { H1, Desktop, MobileAndTablet } from "../../components/styles/common";
import { useState } from "react";

const Navigation = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const onChangeHandler = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <NavigationContainer>
      <MobileAndTablet>
        <LogoAndBackButtonCointainer>
          <button aria-label="Back" onClick={() => navigate(-1)}>
            <BackButton />
          </button>
          <LogoContainer aria-label="Home" to="/">
            <Logo />
          </LogoContainer>
        </LogoAndBackButtonCointainer>
      </MobileAndTablet>

      <Desktop>
        <LogoContainer aria-label="Home" to="/">
          <Logo />
        </LogoContainer>
      </Desktop>

      <MobileAndTablet>
        <SearchBoxcontainer>
          <SendSearchForm />
        </SearchBoxcontainer>
      </MobileAndTablet>

      <Desktop>
        <CrossSitesNavigatorContainer>
          <CrossSitesMenu>
            <CrossSitesMenuBase />
            <H1
              style={{
                "--color": "white",
                "--font-size": "var(--h3-size)",
                "--text-transform": "regular",
                "--font-weight": "var(--root-font-weight-bold)",
              }}
            >
              uTrendTv
            </H1>
          </CrossSitesMenu>
        </CrossSitesNavigatorContainer>
        <SearchBoxcontainer>
          <SendSearchForm />
        </SearchBoxcontainer>
      </Desktop>

      <DropdownMenuContainer onOpenChange={onChangeHandler}>
        <DropdownMenu.Trigger asChild>
          <button aria-label="Menu">
            <MenuButton open={openMenu} />
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <MenuContent data-testid="menu-content" />
        </DropdownMenu.Portal>
      </DropdownMenuContainer>
    </NavigationContainer>
  );
};

export default Navigation;
