import { useMediaQuery } from "react-responsive";
import { CATEGORIES_CLASSES, MEDIA_QUERY_LIMITS } from "../../../config";

import IconCategory, {
  getColor,
} from "../../icon-category/icon-category.component";

import {
  CategoryCardContainer,
  CategoryLabel,
  CategoryTitle,
} from "./category-card.styles";

const DEFAULT_CARD = {
  tagged: true,
  fitText: false,
};

const CategoryCard = ({
  category,
  tagged = DEFAULT_CARD.tagged,
  fitText = DEFAULT_CARD.fitText,
}) => {
  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  const fitTextHandler = (cat) => {
    if (!cat) return;

    const len = cat.length;
    if (!fitText || len < 10) {
      return false;
    }
    // console.log(cat, SMALLER_CATEGORIES);
    return len < 12
      ? "var(--h3-size)"
      : `calc(var(--h3-size) - 0.104166667vw * (${len} - 11))`;
  };

  return (
    <CategoryCardContainer>
      <IconCategory
        category={category}
        width="var(--root-icon-category-width)"
        height="var(--root-icon-category-height)"
      />
      {tagged && !isMobile && (
        <CategoryLabel
          category={category}
          backgroundColor={getColor(CATEGORIES_CLASSES[category])}
        >
          <CategoryTitle
            style={{
              "--smallerFont": `${
                fitTextHandler(CATEGORIES_CLASSES[category])
                  ? fitTextHandler(CATEGORIES_CLASSES[category])
                  : "unset"
              }`,
              "--letterSpacing": `${fitText ? "0" : "1px"}`,
            }}
          >
            {CATEGORIES_CLASSES[category]}
          </CategoryTitle>
        </CategoryLabel>
      )}
    </CategoryCardContainer>
  );
};

export default CategoryCard;
