import {
  ViewRowHeaderContainer,
  ViewInlineRowHeaderContainer,
  ViewRowGreenContainer,
} from "./view-row-header.styles";

const getHeader = (headerType = "stack") =>
  ({
    stack: ViewRowHeaderContainer,
    inline: ViewInlineRowHeaderContainer,
    green: ViewRowGreenContainer,
  })[headerType];

const ViewRowHeader = (props) => {
  // default ViewHeader is "stack"
  const CustomHeader = getHeader(props.type ? props.type : "stack");

  return (
    <CustomHeader
      style={{
        ...props.style,
        "--backgroundRowHeader": props.background,
        "--color": props.color,
        "--paddingViewRowHeader": props.padding,
        "--textAlignViewRowHeader": props.textAlign,
        "--text-transform": props.textTransform,
        "--widthViewRowHeader": props.width,
        "--justifyContentViewRowHeader": props.justifyContentViewRowHeader,
        "--marginViewRowHeader": props.margin,
      }}
    >
      {props.children}
    </CustomHeader>
  );
};

export default ViewRowHeader;
