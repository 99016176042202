import * as Realm from "realm-web";
import * as realmConfig from "../../config.js";
import { toast } from "react-toastify";

export const SIGNIN_TYPE_CLASSES = {
  email: "email-sign-in",
  google: "google-sign-in",
  facebook: "facebook-sign-in",
  signup: "sign-up",
  reset: "reset",
};

export const linkAccounts = async (user, email, password) => {
  const emailPasswordUserCredentials = Realm.Credentials.emailPassword(
    email,
    password,
  );

  try {
    await user.linkCredentials(emailPasswordUserCredentials);
  } catch (e) {
    console.log(e);
  }
};

export const getToken = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const tokenId = params.get("tokenId");
  if (!token || !tokenId) {
    throw new Error("Cannot get valid token and tokenId");
  }
  return { token, tokenId };
};

export const confirmEmail = async (app, paiload) => {
  const { token, tokenId } = paiload;

  try {
    await app.emailPasswordAuth.confirmUser({ token, tokenId });

    toast("Thanks you! 👌");
  } catch (e) {
    throw new Error(`Cannot confirm email: ${e.error}`);
  }
};

const parseAuthenticationError = (err) => {
  const parts = err.message.split(":");
  const reason = parts[parts.length - 1].trimStart();
  if (!reason) return { status: "", message: "" };
  const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
  const match = reason.match(reasonRegex);
  const { status, message } = match?.groups ?? {};
  return { status, message };
};

const handleAuthenticationError = (err, setError) => {
  const { status, message } = parseAuthenticationError(err);
  const errorType = message || status;
  switch (errorType) {
    case "invalid username":
      setError((prevErr) => ({
        ...prevErr,
        email: "Invalid email address.",
      }));
      break;
    case "invalid username/password":
    case "invalid password":
    case "401":
      setError((err) => ({
        ...err,
        password: "Incorrect username and/or password.",
      }));
      break;
    case "name already in use":
    case "409":
      setError((err) => ({ ...err, email: "Email is already registered." }));
      break;
    case "password must be between 6 and 128 characters":
    case "400":
      setError((err) => ({
        ...err,
        password: "Password must be between 6 and 128 characters.",
      }));
      break;
    default:
      throw new Error(`Cannot login: ${err.error}`);
  }
};

export const anonymousLogin = (app = {}) => {
  const anomKey = realmConfig.anomKey;

  try {
    app.logIn(Realm.Credentials.apiKey(anomKey));
  } catch (e) {
    /* handle error */
    console.log("[ERROR]: cannot login with anom");
  }
};

export const loginUsername = async (app, email, password, setError) => {
  const credentials = Realm.Credentials.emailPassword(email, password);

  try {
    await app.logIn(credentials);
    // linkAccounts(user, email, password);
    toast("Wellcome back 🎉");
  } catch (err) {
    handleAuthenticationError(err, setError);
  }
};

export const registerUser = async (app, userDetails, setError) => {
  const { email, password } = userDetails;

  try {
    await app.emailPasswordAuth.registerUser({
      email,
      password,
    });
    toast.info(
      "We have sent you a confirmation email! Please check your mailbox 📫",
    );
  } catch (e) {
    handleAuthenticationError(e, setError);
  }
};

export const askResetUserPassword = async (app, userDetails) => {
  const { email } = userDetails;

  try {
    await app.emailPasswordAuth.sendResetPasswordEmail({
      email,
    });
    toast.info("We have sent you a email! Please check your mailbox 📫");
  } catch (e) {
    throw new Error(`Cannot send email to reset password: ${e.error}`);
  }
};

export const resetUserPassword = async (app, paiload) => {
  const { password, token, tokenId } = paiload;

  try {
    await app.emailPasswordAuth.resetPassword({
      password,
      token,
      tokenId,
    });

    toast("Thanks you! 👌");
  } catch (e) {
    throw new Error(`Cannot change password: ${e.error}`);
  }
};

export const deleteUser = async (app, user, setUser) => {
  try {
    await app.deleteUser(user);
    setUser(app.currentUser);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const signInWithFacebook = async (app, setError) => {
  console.log("sign in with Facebook");
  // The redirect URI should be on the same domain as this app and
  // specified in the auth provider configuration.
  // const redirectUri = "https://localhost:3000/auth/callback";
  const redirectUri = process.env.REACT_APP_AUTHCALLBACK;
  const credentials = Realm.Credentials.facebook(redirectUri);

  try {
    const user = await app.logIn(credentials);
    // `App.currentUser` updates to match the logged in user
    console.assert(user.id === app.currentUser.id);

    return user;
  } catch (e) {
    console.log("Cannot login with Facebook");

    setError((prevErr) => ({
      ...prevErr,
      facebook: "Cannot login with Facebook",
    }));
  }
};

export const signInWithGoogle = async (app, setError) => {
  // TODO:  qui avremmo bisogno di sapere la mail che utilizza per verrificare se esiste già. Ma non la possiamo avere //
  console.log("sign in with Google");

  const redirectUri = process.env.REACT_APP_AUTHCALLBACK;
  const credentials = Realm.Credentials.google(redirectUri);

  try {
    const user = await app.logIn(credentials);
    // `App.currentUser` updates to match the logged in user
    console.assert(user.id === app.currentUser.id);

    return user;
  } catch (e) {
    console.log("Cannot login with Google");

    setError((prevErr) => ({
      ...prevErr,
      google: "Cannot login with Google",
    }));
  }
};

export const isAnon = (user) => {
  // anon is true iif: no user, or if user.provider is anon-user
  return !user || user.providerType === "anon-user";
};

export const isAuthenticated = (user) => {
  // true iif: user not null or undefined and provider is not anon-user
  return !isAnon(user);
};

export const sendPathInfo = async (user, args) => {
  try {
    const result = await user.callFunction("insertPageViews", ...args);
    // console.log(result);
    return result;
  } catch (e) {
    console.log("Failed to call function", e);
  }
};
