import styled from "styled-components/macro";

import {
  boxShadow,
  centeringAbsolutePositionContainer,
} from "../../styles/common";

import { MEDIA_QUERY_LIMITS } from "../../../config";

export const SlantedVideoCardContainer = styled.div`
  position: relative;
  transform-origin: top;
  width: var(--videoCardWidth, var(--root-video-card-slanted-width));
  height: var(--videoCardHeight, var(--root-video-card-slanted-height));
  transform: skew(-12deg);
  overflow: hidden;
  ${boxShadow};
  scale: 1;
  background-color: white;
  transition: all 0.4s linear;

  img {
    object-fit: cover;
    height: 100%;
    max-width: none;
    transform-origin: center;
    position: absolute;
    top: 0;
    left: calc(
      var(--videoCardHeight, var(--root-video-card-slanted-height)) / 2 /
        -4.70463010948
    );

    width: calc(
      var(--videoCardWidth, var(--root-video-card-slanted-width)) +
        (
          var(--videoCardHeight, var(--root-video-card-slanted-height)) /
            4.70463010948
        )
    );
    transform: skew(12deg);
  }

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    &:hover {
      transition: all 0.2s linear;
      scale: 1.115;
      margin-right: 0.625vw;
      margin-left: 0.625vw;
    }
  }

  &:active {
    cursor: grabbing;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 65%;
  height: 65%;
  ${centeringAbsolutePositionContainer};

  &:hover {
    cursor: pointer;
  }
`;

export const Rank = styled.h3`
  padding: max(0.104166667vw, 2px) max(0.3125vw, 6px) max(0.9375vw, 18px)
    max(0.3125vw, 6px);
  color: white;
  background: var(--background, var(--veryperiColor));
  float: right;
  transform: skew(0);
  width: min-content;
`;
