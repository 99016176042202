import { DEFAULT_SWIPER_OPTIONS } from "../../../config";

import ViewSwiperBase from "../view-swiper-base/view-swiper-base.component";

import { Arrow } from "../../lists/videos-swiper/videos-swiper.styles";

import SVGLeftArrow from "../../icons/arrows/left-arrow.component";
import SVGRightArrow from "../../icons/arrows/right-arrow.component";

const ViewPaginatedSwiper = ({
  padding,
  moveLeftArrow,
  loadMore = DEFAULT_SWIPER_OPTIONS.loadMore,
  arrowsColor,
  children,
}) => {
  return (
    <ViewSwiperBase
      freeMode={true}
      slidesPerView={"auto"}
      watchSlidesProgress={true}
      style={{
        "--paddingSwiper": padding,
      }}
      navigation={{
        nextEl: ".swiper-videos-list-button-next",
        prevEl: ".swiper-videos-list-button-prev",
      }}
      onReachEnd={loadMore}
    >
      {children}

      <Arrow
        className="swiper-videos-list-button-prev"
        style={{
          "--color": arrowsColor,
          "--moveLeftArrow": moveLeftArrow,
        }}
      >
        <SVGLeftArrow />
      </Arrow>
      <Arrow
        className="swiper-videos-list-button-next"
        style={{
          "--color": arrowsColor,
        }}
      >
        <SVGRightArrow />
      </Arrow>
    </ViewSwiperBase>
  );
};

export default ViewPaginatedSwiper;
