import { ViewRowContainer } from "./view-row.styles";

const ViewRow = (props) => {
  return (
    <ViewRowContainer
      style={{
        "--backgroundViewRow": props.background,
        "--marginViewRow": props.margin,
        "--paddingViewRow": props.padding,
        "--positionViewRow": props.position,
        "--overflow": props.overflow,
      }}
    >
      {props.header}
      {props.content}
    </ViewRowContainer>
  );
};

export default ViewRow;
