import { useEffect } from "react";

import Navigation from "../navigation/navigation.component";
import Footer from "../footer/footer.component";
import { ToastContainer, Zoom } from "react-toastify";
import { useParams, Outlet, useLocation } from "react-router-dom";
import { getColor } from "../../components/icon-category/icon-category.component";
import { useRealmApp } from "../../contexts/realmapp.context";
import { sendPathInfo } from "../../utils/realm/realm.utils.js";
import { CATEGORIES_CLASSES } from "../../config";

import { MainContainer } from "./layout.styles";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  const app = useRealmApp();

  const { category } = useParams();
  let background = null;

  if (category) {
    background = getColor(CATEGORIES_CLASSES[category]);
  }

  let location = useLocation();

  useEffect(() => {
    const currentEnv = process.env.NODE_ENV;
    const pathInfo = location?.pathname ? location.pathname : "";
    const videoId = undefined;
    const args = [pathInfo, videoId];

    if (currentEnv === "production") {
      sendPathInfo(app.currentUser, args);
    } else {
      // console.log(`[WARN] we are in ${currentEnv}: nothing to send`, { args });
    }
  }, [location, app?.currentUser, app?.currentUser?.id]);

  return (
    <>
      <Navigation />
      <MainContainer
        role="main"
        style={{
          "--root-background-color": background,
        }}
      >
        {category ? <Outlet context={[category]} /> : <Outlet />}
      </MainContainer>
      <Footer />
      <ToastContainer autoClose={5000} theme="dark" transition={Zoom} />
    </>
  );
};

export default Layout;
