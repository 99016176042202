import styled from "styled-components/macro";

export const VideosGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(var(--column-width, var(--root-video-card-dimension-width)), 1fr)
  );
  gap: var(--root-vertical-space-1) 0;
  margin: 0 auto;
  padding: var(--root-vertical-space-0) var(--root-horizontal-space-2)
    var(--root-vertical-space-1);
  width: 100%;
  justify-items: center;
`;
