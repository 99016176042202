import styled from "styled-components/macro";
import ViewRowHeader from "../view-row-header/view-row-header.component";
import { Wrapper } from "../view-row-header/view-row-header.styles.jsx";
import { getCategoryColor } from "../../../utils/custom/custom.utils";

import IconCategory from "../../icon-category/icon-category.component";

export const WrapperDiv = styled(Wrapper)`
  position: relative;
`;

const ViewInlineIconListHeader = ({ category, children, ...delegated }) => {
  const color = getCategoryColor(category);

  return (
    <ViewRowHeader
      {...delegated}
      type={"inline"}
      color={color}
      width={"var(--root-inline-header-width)"}
    >
      <WrapperDiv>
        {children}
        <IconCategory category={category} width={"5vw"} />
      </WrapperDiv>
    </ViewRowHeader>
  );
};

export default ViewInlineIconListHeader;
