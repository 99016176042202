import { useNavigate } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import * as Tooltip from "@radix-ui/react-tooltip";
import { isString } from "lodash";
import { LogoCompact } from "../../components/logo/logo.component";

import {
  SimpleContainer,
  ButtonShow,
  OpenIcon,
  CloseIcon,
  Reload as ReloadIcon,
  PopoverContent,
  PopoverArrow,
  PopoverClose,
} from "../styles/common";

import { isAtlasDev, isDevelopmentTF } from "../../config";

const Error = ({ message }) => {
  const navigate = useNavigate();

  message = isString(message) ? message : JSON.stringify(message);
  // console.log("message", message);

  const onClickHandler = () => {
    // console.log("reloaded");
    navigate(0);
  };

  return (
    <SimpleContainer>
      <div>
        <LogoCompact />
        <p>Something went wrong</p>
      </div>

      {isAtlasDev || isDevelopmentTF ? (
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonShow aria-label="Show Error">
              <OpenIcon />
            </ButtonShow>
          </Popover.Trigger>
          <Popover.Portal>
            <PopoverContent sideOffset={15}>
              <div>
                <p>{message ? message : "no more info"}</p>
              </div>
              <PopoverClose aria-label="Close">
                <CloseIcon />
              </PopoverClose>
              <PopoverArrow />
            </PopoverContent>
          </Popover.Portal>
        </Popover.Root>
      ) : (
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <ButtonShow onClick={onClickHandler}>
                <ReloadIcon />
              </ButtonShow>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content side={"bottom"} sideOffset={5}>
                Reload page
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      )}
    </SimpleContainer>
  );
};

export default Error;
