import { FreeMode, Navigation } from "swiper";
import { DEFAULT_SWIPER_OPTIONS } from "../../../config";

import { ViewSwiperContainer } from "./view-swiper-base.styles";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

const SWIPER_MODULE_TYPE = {
  none: "",
  freeMode: "freeMode",
  navigation: "navigation",
  lazy: "lazy",
  grid: "grid",
};

const getSwiperModules = (moduleType = SWIPER_MODULE_TYPE.none) =>
  ({
    [SWIPER_MODULE_TYPE.none]: null,
    [SWIPER_MODULE_TYPE.freeMode]: FreeMode,
    [SWIPER_MODULE_TYPE.navigation]: Navigation,
  })[moduleType];

const SwiperBase = ({
  freeMode = DEFAULT_SWIPER_OPTIONS.freeMode,
  navigation = DEFAULT_SWIPER_OPTIONS.navigation,
  slidesPerView = DEFAULT_SWIPER_OPTIONS.slidesPerView,
  spaceBetween = DEFAULT_SWIPER_OPTIONS.spaceBetween,
  grabCursor = DEFAULT_SWIPER_OPTIONS.grabCursor,
  style = {},
  children,
  ...restProps
}) => {
  const modules = [];

  freeMode && modules.push(getSwiperModules(SWIPER_MODULE_TYPE.freeMode));
  navigation && modules.push(getSwiperModules(SWIPER_MODULE_TYPE.navigation));

  return (
    <ViewSwiperContainer
      freeMode={freeMode}
      navigation={navigation}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      grabCursor={grabCursor}
      watchSlidesProgress={true}
      modules={modules}
      {...(restProps.onReachEnd ? { onReachEnd: restProps.onReachEnd } : {})}
      style={{
        ...style,
        ...(restProps.padding ? { "--paddingSwiper": restProps.padding } : {}),
      }}
    >
      {children}
    </ViewSwiperContainer>
  );
};

export default SwiperBase;
