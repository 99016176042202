import { useState, useEffect, createContext, useContext } from "react";
import * as Realm from "realm-web";

const RealmAppContext = createContext();

export const useRealmApp = () => {
  const app = useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`,
    );
  }
  return app;
};

export const RealmAppProvider = ({ appId, children }) => {
  // Returns an instance of an app. If an app with the specified id hasn't been created, a new app instance will be created.
  // console.log("app", app);
  const [app, setApp] = useState(Realm.getApp(appId));

  // if app.id change, we get a new app
  useEffect(() => {
    console.log(`[SETTING] app Realm: ${appId}`);
    setApp(Realm.getApp(appId));
  }, [appId]);

  const [currentUser, setCurrentUser] = useState(app?.currentUser);

  async function logIn(credentials) {
    try {
      await app?.logIn(credentials);
      // If successful, app.currentUser is the user that just logged in
      setCurrentUser(app?.currentUser);
    } catch (e) {
      /* handle error. We can throw an error to the error ErrorBoundary
       ErrorBoundary does not work with async? cfr: https://github.com/bvaughn/react-error-boundary#readme */
      console.error("[ERROR]: cannot logIn", e);
    }
  }

  async function logOut() {
    try {
      // Log out the currently active user
      await app.currentUser.logOut();
      // If another user was logged in too, they're now the current user.
      // Otherwise, app.currentUser is null.
      setCurrentUser(app.currentUser);
    } catch (e) {
      /* handle error */
      console.error("[ERROR]: cannot logOut", e);
    }
  }

  async function dumpUser() {
    try {
      // Log out the currently active user
      setCurrentUser(null);
    } catch (e) {
      /* handle error */
      console.error("[ERROR]: cannot dump user", e);
    }
  }

  const wrapped = { ...app, currentUser, logIn, logOut, dumpUser };

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};
