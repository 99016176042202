import * as Popover from "@radix-ui/react-popover";
import { isString } from "lodash";
import { useEffect, useState } from "react";
import { Loading } from "../../components/loading/loading.component";

import { Logo } from "../../components/logo/logo.component";
import {
  H2,
  ErrorContainer,
  ButtonShow,
  OpenIcon,
  CloseIcon,
  PopoverContent,
  PopoverArrow,
  PopoverClose,
} from "../../components/styles/common";
import { isAtlasDev, isDevelopmentTF } from "../../config";

const ErrorPage = ({ message = "something went wrong!" }) => {
  message = isString(message) ? message : JSON.stringify(message);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    // remove loading after 5 secs
    if (showLoading) {
      setTimeout(() => {
        setShowLoading(false);
      }, 5000);
    }
  }, [showLoading]);

  return (
    <ErrorContainer>
      <Logo />
      {showLoading ? (
        <Loading />
      ) : (
        <H2
          style={{
            "--font-size": "var(--h2-size)",
            "--text-transform": "capitalized",
            padding: "2.5rem 0",
          }}
        >
          Coming back soon!
        </H2>
      )}
      {(isAtlasDev || isDevelopmentTF) && (
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonShow aria-label="Show Error">
              <OpenIcon />
            </ButtonShow>
          </Popover.Trigger>
          <Popover.Portal>
            <PopoverContent sideOffset={15}>
              <div>
                <p>{message}</p>
              </div>
              <PopoverClose aria-label="Close">
                <CloseIcon />
              </PopoverClose>
              <PopoverArrow />
            </PopoverContent>
          </Popover.Portal>
        </Popover.Root>
      )}
    </ErrorContainer>
  );
};

export default ErrorPage;
