import styled from "styled-components/macro";

const Bar = styled.path`
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition:
    stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const BarTop = styled(Bar)`
  stroke-dasharray: 60 207;
  stroke-width: 6;
  ${({ open }) =>
    open &&
    `
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  `}
`;

export const BarMiddle = styled(Bar)`
  stroke-dasharray: 60 60;
  stroke-width: 6;
  ${({ open }) =>
    open &&
    `
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  `}
`;

export const BarBottom = styled(Bar)`
  stroke-dasharray: 60 207;
  stroke-width: 6;
  ${({ open }) =>
    open &&
    `
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  `}
`;

export const SvgWrapper = styled.div`
  --size: var(--root-icons-menubar);
  width: var(--size);
  height: var(--size);
`;
