import { useMediaQuery } from "react-responsive";
import { SwiperSlide } from "swiper/react";
import ViewSwiperBase from "../../views/view-swiper-base/view-swiper-base.component";

import { MEDIA_QUERY_LIMITS } from "../../../config";
import CategoryCard from "../../cards/category-card/category-card.component";
import ViewRowHeader from "../../../components/views/view-row-header/view-row-header.component";
import { H2 } from "../../styles/common";

const SwiperCategoriesList = ({ data = [], tagged = true }) => {
  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  const simply = isMobile || !tagged;

  return (
    <ViewSwiperBase
      padding={simply ? "0 0 0 var(--root-padding-swiper-mobile)" : "0"}
      spaceBetween={"2%"}
      freeMode={true}
      grabCursor={true}
    >
      {!simply && (
        <SwiperSlide
          style={{
            width: "min-content",
            whiteSpace: "nowrap",
          }}
        >
          <ViewRowHeader
            padding={
              "var(--root-vertical-space-1) calc(var(--root-category-swiper-removed-margin) * -1) 0 var(--root-horizontal-space-1)"
            }
            textTransform={"uppercase"}
          >
            <H2
              style={{
                "--font-weight": "var(--root-font-weight-bold)",
                "--font-size": "var(--h3-plus-size)",
                overflowWrap: "normal",
              }}
            >
              Categorie
            </H2>
          </ViewRowHeader>
        </SwiperSlide>
      )}
      {data?.length > 0
        ? data.map((cat, i) => (
            <SwiperSlide
              key={cat._id ? cat._id : i.toString()}
              style={{
                width: "min-content",
                marginLeft: `${
                  simply ? "0" : "var(--root-category-swiper-removed-margin)"
                }`,
              }}
            >
              <CategoryCard
                category={cat.name}
                fitText={true}
                tagged={tagged}
              />
            </SwiperSlide>
          ))
        : null}
    </ViewSwiperBase>
  );
};

export default SwiperCategoriesList;
