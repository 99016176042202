import styled from "styled-components/macro";

import { VideoCard } from "../../styles/common";

export const VideoCardContainer = styled(VideoCard)`
  --border-radius: var(--root-border-radius-0);
  width: var(--videoCardWidth, var(--root-video-card-dimension-width));

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`;
