import styled from "styled-components/macro";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";

import { CityContainer } from "../../cards/city-card/city-card.styles";
import { MEDIA_QUERY_LIMITS } from "../../../config";

export const CitiesContainer = styled.div`
  position: relative;
`;

export const SwiperCitiesContainer = styled(Swiper)`
  --margin-offset: 0.4vw;
  --tile-width: calc(100vw / 3);
  --tile-height: calc(var(--tile-width) / 60 * 29);

  @media screen and (max-width: 499px) {
    --tile-width: calc(100vw / 2);
  }

  height: calc(var(--tile-height) * 2 + var(--margin-offset));
  margin: var(--root-vertical-space-0);

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    --x-offset: calc(calc(var(--root-horizontal-space-3) * 1.4));
    --tile-width: calc((100vw - var(--x-offset) * 2) / 3);
    margin: var(--root-vertical-space-0) var(--x-offset);
  }

  ${CityContainer} {
    border-radius: calc(var(--root-border-radius-0) + var(--margin-offset));
    margin: var(--margin-offset);
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

export const SwiperSlideContainer = styled(SwiperSlide)`
  height: min-content;
`;

export const Arrow = styled.button`
  --swiper-navigation-color: var(--secondaryColor);
  font-weight: var(--root-font-weight-bold);

  display: none;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    display: block;
  }

  &.swiper-button-prev {
    left: calc(var(--root-horizontal-space-3) * 0.5);
  }

  &.swiper-button-next {
    right: calc(var(--root-horizontal-space-3) * 0.5);
  }
`;
