import styled from "styled-components/macro";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { centeringAbsolutePositionContainer } from "../../styles/common";

export const CityContainer = styled(AspectRatio.Root)`
  position: relative;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  /* background: rgba(200, 0, 0, 0.2); */
  width: 74%;
  height: 60%;
  ${centeringAbsolutePositionContainer};

  &:hover {
    cursor: pointer;
  }
`;
