import styled from "styled-components/macro";

import {
  LeadContainer,
  EpisodeUtrendersContainer,
} from "../common/lead.styles";
import { MEDIA_QUERY_LIMITS } from "../../../config";

export const LeadProgramBlueHorizonsContainer = styled(LeadContainer)`
  padding-top: 4rem;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    padding-top: 9rem;
  }
  justify-content: center;
  background:
    linear-gradient(
      rgba(var(--greenColorRgb), 0.6),
      rgba(var(--root-background-color-white-1-rgb), 0.6) 75%,
      rgba(var(--root-background-color-white-1-rgb), 1)
    ),
    var(--background-image) no-repeat center center;

  background-size: cover;
`;

export const BlueHorizonsTitleAndLogoContainer = styled.div`
  width: var(--root-video-player-width);

  text-align: center;
  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    text-align: left;
  }

  * {
    line-height: 1;
    display: inline-block;
  }
`;

export const BlueHorizonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EpisodeUtrendersMobileGreenProgramContainer = styled.div`
  margin-top: var(--root-vertical-space-0-rem);
  border-top: 3px solid var(--greenColor);
`;

export const EpisodeUtrendersContainerNoBorders = styled(
  EpisodeUtrendersContainer,
)`
  &:before {
    content: none;
  }
`;

export const EpisodeInfoContainer = styled.div`
  background: var(--root-background-color-white-1);
`;

export const EpisodeTitleContainer = styled.div`
  padding: var(--root-vertical-space-1) var(--root-horizontal-space-3) 0;
  text-align: left;
  background: var(--root-background-color-header);

  @media screen and (max-width: ${MEDIA_QUERY_LIMITS.maxWidthMobile}px) {
    padding: var(--root-vertical-space-1) var(--root-horizontal-space-3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 3px solid var(--greenColor);
    border-bottom: 3px solid var(--greenColor);
    background: var(--root-background-episode-info-card);
  }
`;

export const PlayContainer = styled.div`
  position: absolute;
  bottom: calc(var(--root-vertical-space-0) / 2);
  left: var(--root-horizontal-space-2);
  display: flex;
  gap: var(--root-horizontal-space-1);
  margin-bottom: var(--root-vertical-space-0-rem);
  align-items: center;
  z-index: 4;
`;

// TODO:  import and add customization //
export const EpisodeDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: var(--root-vertical-space-1) var(--root-horizontal-space-3);

  @media screen and (max-width: ${MEDIA_QUERY_LIMITS.maxWidthMobile}px) {
    padding-top: max(var(--root-vertical-space-1), 10px);
    padding-bottom: max(var(--root-vertical-space-1), 10px);

    border-bottom: 3px solid var(--greenColor);
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: min(3.229166667vw, 62px);
`;

export const EpisodeDescription = styled.div`
  --font-size: var(--h3-size);
  text-align: left;
`;
