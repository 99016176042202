import * as Avatar from "@radix-ui/react-avatar";
import styled from "styled-components";

export const AvatarRoot = styled(Avatar.Root)`
  --size: max(30px, 2.34375vw);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  width: var(--size);
  height: var(--size);
  border-radius: 100%;
`;

export const AvatarImage = styled(Avatar.Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

export const AvatarFallback = styled(Avatar.Fallback)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 40, 40, 0.1);
  font-size: var(--p-size);
  line-height: 1;
  font-weight: var(--root-font-weight-bold);
  text-transform: uppercase;
`;
