import styled, { css } from "styled-components";

const variables = css`
  --size: calc(100vw / 2);

  @media screen and (min-width: 420px) {
    --size: max(11.458333333vw, 220px);
  }
`;

export const List = styled.ul`
  ${variables};
  display: grid;
  justify-items: center;
  padding: var(--root-vertical-space-1);
  margin: 0;
  list-style: none;
  grid-template-columns: 1fr 1fr;

  @media screen and (min-width: 420px) {
    grid-template-columns: repeat(auto-fit, minmax(var(--size), 1fr));
  }

  row-gap: max(var(--root-horizontal-space-1), 12px);
`;

export const Slot = styled.div`
  ${variables};
  display: flex;
  gap: max(var(--root-horizontal-space-1, 6px));
  width: var(--size);
  align-items: center;
  text-transform: uppercase;
`;
