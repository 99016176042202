import VideoTestLargeCard from "../../../test-components/video-test-large-card/video-test-large-card.component";
import VideoTestCard from "../../../test-components/video-test-card/video-test-card.component";
import VideoCard from "../../cards/video-card/video-card.component";
import VideoCardStory from "../../cards/video-card-story/video-card-story.component";
import { DEFAULT_VIDEO_CARD } from "../../../config";

// TODO: Now only story card use the bopder color prop value //

import { VideosGridContainer } from "./videos-grid.styles";

import {
  DEFAULT_MOBILE_HOVER_PADDING as DEFAULT_HOVER_PADDING,
  MEDIA_QUERY_LIMITS,
} from "../../../config.js";
import { useMediaQuery } from "react-responsive";

export const CARD_TYPE = {
  test: "testCard",
  testLarge: "testLargeCard",
  video: "videoCard",
  story: "storyCard",
};

const getCard = (cardType) =>
  ({
    [CARD_TYPE.testLarge]: VideoTestLargeCard,
    [CARD_TYPE.test]: VideoTestCard,
    [CARD_TYPE.video]: VideoCard,
    [CARD_TYPE.story]: VideoCardStory,
  })[cardType];

const VideosGrid = ({
  data = [],
  cardType = CARD_TYPE.video,
  borderColor = DEFAULT_VIDEO_CARD.borderColor,
  videoCustomWidth,
}) => {
  const isDesktop = useMediaQuery({
    minWidth: MEDIA_QUERY_LIMITS.minWidthDesktop,
  });

  const CustomCard = getCard(cardType);
  const isStory = cardType === CARD_TYPE.story;

  const adaptedWidth = isDesktop
    ? isStory
      ? "var(--root-video-card-story-width)"
      : "var(--root-video-card-in-grid)"
    : "var(--root-video-card-dimension-width)";

  return (
    <VideosGridContainer
      style={{
        "--column-width": `${
          isStory
            ? "var(--root-video-card-story-width)"
            : `${videoCustomWidth ? videoCustomWidth : adaptedWidth}`
        }`,
      }}
    >
      {data.map((item, i) => (
        <CustomCard
          key={item._id ? item._id : i.toString()}
          {...item}
          collisionPadding={DEFAULT_HOVER_PADDING}
          width={videoCustomWidth ? "95%" : adaptedWidth}
          borderColor={borderColor}
        />
      ))}
    </VideosGridContainer>
  );
};

export default VideosGrid;
