import { useState, useEffect } from "react";

import posterFallback from "../../assets/poster-fallback.png";

const DEFAULT_IMAGE = {
  src: posterFallback,
  alt: "uTrendTv logo",
};

const Image = ({
  src,
  altSrc = DEFAULT_IMAGE.src,
  alt = DEFAULT_IMAGE.alt,
}) => {
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const onErrorHandler = () => {
    if (imageSrc !== altSrc) {
      // console.log("We have an error: try altSrc");
      setImageSrc(altSrc);
      return;
    }
    // We re out of options change to poster default
    if (imageSrc !== DEFAULT_IMAGE.src) {
      // console.log("We have an error, replace img src with fall back");
      setImageSrc(DEFAULT_IMAGE.src);
      return;
    }
  };

  return (
    <img loading="lazy" src={imageSrc} alt={alt} onError={onErrorHandler} />
  );
};

export default Image;
