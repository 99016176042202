import styled from "styled-components/macro";

import { MEDIA_QUERY_LIMITS } from "../../../config";
import { Swiper } from "swiper/react";

export const SwiperContainer = styled(Swiper)`
  margin: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding: 0;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
    padding: 0 calc(var(--root-utrender-card-width) / 2) 0
      calc(var(--root-utrender-card-width) / 2);
  }

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    margin: 0 0 0 calc(var(--root-utrender-card-height) * 0.14054 / 2 * -1);

    .swiper-slide:not(.swiper-slide-visible) {
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0s 0.3s,
        opacity 0.3s linear;
    }

    .swiper-slide.swiper-slide-visible {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.4s linear;
    }
  }
`;

export const Arrow = styled.button`
  color: var(--color, var(--secondaryColor));
  display: none;
  font-weight: var(--root-font-weight-bold);
  height: calc(var(--swiper-navigation-size) * 2.5);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(var(--swiper-navigation-size) * 2.5);
  z-index: 10;

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    display: block;
  }

  &.swiper-people-list-button-prev,
  &.swiper-rtl &.swiper-people-list-button-next {
    left: 0;
  }

  &.swiper-people-list-button-next,
  &.swiper-rtl &.swiper-people-list-button-prev {
    right: 0;
  }

  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: auto;
  }
`;
