import styled from "styled-components/macro";
import { LeadContainer as LeadBase } from "../common/lead.styles";
import { LeadHomeTitleContainerStyle } from "../lead-home-title/lead-home-title.styles";

export const LeadHomeContainer = styled(LeadBase)`
  align-items: start;
  margin-bottom: var(--5MPx);

  ${LeadHomeTitleContainerStyle} {
    display: flex;
  }
`;
