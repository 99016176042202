import styled from "styled-components/macro";
import { centeringContainer } from "../styles/common";

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: var(--min-height);
  ${centeringContainer};
  z-index: 10;
`;

export const ImageLoadingContainer = styled.div`
  position: absolute;
`;
